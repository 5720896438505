.language-select {
  padding: 0 1rem;
}

.language-select__item {
  padding: 0 0.5rem;
  font-family: "Roboto", serif;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-gray);
  cursor: pointer;
}

.language-select__item--active {
  color: var(--color-black);
}

.language-select__item:hover {
  color: var(--color-black);
}

.language-select__item input[type="radio"] {
  display: none;
}
