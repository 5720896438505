.faq-page__card {
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 0.625rem;
}

.faq-page--minimal-header {
  background: transparent;
}

.faq-page--minimal-header .route-page__title {
  text-align-last: left;
  color: var(--color-black);
}

.faq-page__card[open] {
  background-color: var(--color-light-gray);
}

.faq-page__card-title {
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  line-height: 1.5;
  text-transform: uppercase;
}

.faq-page__card-desc {
  padding-left: 1.25rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .faq-page__card-title {
    font-size: 1.125rem;
  }
}
