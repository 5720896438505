@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

body {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-black);
}

h1 {
  margin: 0 0 1.5rem;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 4rem;
  text-transform: uppercase;
  color: var(--color-black);
}

h3 {
  margin: 0 0 3rem;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  color: var(--color-black);
}

h4 {
  margin: 0 0 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: var(--color-black);
}

h5 {
  margin: 0 0 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: var(--color-black);
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading--xxl {
  font-weight: bold;
  font-size: 7.5rem;
  line-height: 7.375rem;
}

.page-title {
  margin: 0 0 3rem;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 4rem;
  text-transform: uppercase;
  color: var(--color-black);
}

.link {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: var(--color-black);
}

.cta-link {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: var(--color-well-read);
}

small,
.ui-small {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 0.5rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: var(--color-black);
}

/* Tablet */
@media (max-width: 768px) {
  .heading--xxl {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}

/* Mobile */
@media (max-width: 568px) {
  .heading--xxl {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
