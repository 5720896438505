:root {
  --content-width: 75rem;
  --content-padding: 1.5rem;
}

@media (max-width: 768px) {
  :root {
    --content-padding: 1rem;
  }
}
