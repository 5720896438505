.job-item-card__cover {
  width: 100%;
  height: 100%;
  height: 16.25rem;
  overflow: hidden;
}
.job-item-card__cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
