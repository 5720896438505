.contact__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  list-style: none;
}

.contact__list .contact__list-item {
  display: flex;
  flex: 1 1 33%;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2.25rem 1.75rem;
  margin: 0.5rem;
  border-radius: 0.625rem;
  border: 1px solid var(--color-well-read);
  font-size: 22px;
  color: var(--color-well-read);
  background-color: #fff;
}

.contact__list-icon {
  width: 32px;
  height: 32px;
  flex: 0 0 auto;
  display: block;
  margin-right: 1rem;
}

.contact--column .contact__list {
  flex-direction: column;
}

.contact__list-item a {
  color: currentColor;
  text-decoration: none;
}

.contact--column .contact__list-item {
  margin-right: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .contact__list {
    flex-direction: column;
  }
}
