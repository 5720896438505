.about-page p {
  font-size: 1.125rem;
  line-height: 1.5;
}

.about-page__logo {
  display: block;
  max-width: 180px;
  height: auto;
  margin: 0 auto;
}

.about-page__logo--fixed {
  position: fixed;
  top: 100%;
  left: 100%;
  transform: translate(-140%, -140%);
  display: block;
  max-width: 380px;
  height: auto;
  margin: 0 auto;
  opacity: 0.24;
  pointer-events: none;
  z-index: -1;
}

.doc-photo {
  display: block;
  margin: 0 auto;
  width: 50%;
  height: auto;
}
