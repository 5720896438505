@import "./colors.css";
@import "./settings.css";
@import "./typography.css";
@import "./layout.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f0f0f0; */
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main.content {
  flex: 1;
}
