.job-item-card__text {
  flex: 1 1 auto;
  display: -webkit-box;
  margin: 0 0 1rem;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 0.875rem;
  line-height: 1.125rem;
  overflow: hidden;
}
