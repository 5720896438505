.home-page {
  display: block;
  padding-top: 4.5rem;
}

.home-page__title {
  margin-bottom: 3.5rem;
}

.home-page__job-list {
  padding: 0;
  margin-bottom: 1.5rem;
  list-style-position: inside;
}

.home-page__job-list li {
  margin-bottom: 1rem;
  font-weight: 400;
  text-transform: uppercase;
}

.home-page__benefit-list {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 1rem;
}

.home-page__benefit-list li::marker {
  font-size: 1;
  font-weight: bold;
}

@media (max-width: 768px) {
  .home-page {
    padding-top: 2.25rem;
  }

  .home-page__title {
    margin-bottom: 1.5rem;
  }

  .home-page__benefit-list {
    gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
