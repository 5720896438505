.header {
  display: block;
  position: sticky;
  top: 0;
  -webkit-box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 9;
}

.header__inner {
  display: flex;
  align-items: center;
  padding: 1rem var(--content-padding) 1rem;
  max-width: var(--content-width);
  width: 100%;
  margin: 0 auto;
}

.header__logo {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 2rem;
}

.logo__image {
  display: block;
  width: 100%;
  height: 100%;
}

.header__content {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.mobile-navigation-control__open-button {
  display: none;
}

.header__mobile-navigation-control {
  display: none;
}

.header__navigation {
  display: flex;
  flex: 1 0 auto;
}

.navigation__link {
  display: block;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-gray);
}

.navigation__link:not(:last-child) {
  margin-right: 3rem;
}

.navigation__link::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  opacity: 0;
}

.navigation__link:hover {
  color: var(--color-black);
}
.navigation__link:hover::after {
  opacity: 1;
}

.navigation__link--active {
  color: var(--color-black);
}

.header__info {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header__contacts {
  flex: 0 0 auto;
  margin-left: auto;
}

.header__tel {
  font-size: 1.375rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--color-well-read);
}

@media (max-width: 1024px) {
  .header__content {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    padding: 42px 24px;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
  }

  .header__content--open {
    transform: translateX(100%);
  }

  .header__mobile-navigation-control {
    display: block;
    text-align: right;
    margin-bottom: 42px;
  }

  .mobile-navigation-control__open-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: #fff;
    border: 1px solid rgb(117, 115, 115);
  }

  .mobile-navigation-control__close-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #333;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bold;
  }

  .header__navigation {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  .navigation__link {
    font-size: 1.125rem;
  }

  .navigation__link:not(:last-child) {
    margin-right: 0 !important;
    margin-bottom: 12px;
  }

  .header__info {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0.5rem var(--content-padding);
  }

  .header__logo {
    margin-right: 1rem;
  }

  .header__info-tel {
    font-size: 1rem;
  }

  .navigation__link:not(:last-child) {
    margin-right: 1rem;
  }
}

@media (max-width: 568px) {
  .header__info-tel {
    font-size: 1rem;
    display: none;
  }
}
