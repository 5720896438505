.content-block {
  width: 100%;
  max-width: calc(var(--content-width));
  margin: 0 auto 5.625rem;
  padding: 0 var(--content-padding);
  /* -webkit-box-shadow: 0px 22px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 22px 40px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 0.625rem; */
}

@media (max-width: 768px) {
  .content-block {
    margin: 0 auto 3.5rem;
  }
}
