.page-title-slot {
  display: block;
  margin-bottom: 2rem;
  background-color: var(--color-well-read);
}

.page-title-slot__inner {
  display: block;
  padding: var(--content-padding);
  width: 100%;
  max-width: var(--content-width);
  margin: 0 auto;
}
