.content-image-block {
  width: 100%;
  height: 25rem;
  max-width: var(--content-width);
  margin: 0 auto 7.5rem;
}

.content-image-block__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-image-block--left-align {
  padding-left: 0;
  padding-right: 11.125rem;
}

@media (max-width: 768px) {
  .content-image-block {
    margin: 0 auto 3.5rem;
  }
}

@media (max-width: 568px) {
  .content-image-block {
    margin: 0 auto 2rem;
  }
}
