.job-item-card__action-button {
  display: block;
  padding: 0.75rem 1rem;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-well-read);
}

.job-item-card__action-button:hover {
  background-color: var(--color-well-read-light);
}
