.footer {
  padding: 3rem 0;
  background-color: var(--color-dark-gray);
}

.footer__content {
  width: 100%;
  margin: 0 auto;
  max-width: var(--content-width);
}

.footer__socials-list {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.social-list__item {
  flex: 0 0 auto;
  margin: 0 2rem;
}

.social-list__item-icon {
  margin-right: 0.5rem;
}

.social-list__link {
  display: flex;
  align-items: center;
  color: var(--color-gray);
  text-decoration: none;
}

.social-list__link:hover {
  color: var(--color-white);
  text-decoration: underline;
}
