.cta-nav-link {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: var(--color-well-read);
}

.cta-nav-link__label {
  flex: 1 0 auto;
  margin-right: 0.5rem;
}

.cta-nav-link__icon {
  flex: 1 0 auto;
  width: 1.5rem;
  height: 1.5rem;
}
